<template>
    <div class="pa-5 ma-3 white rounded-lg">
        <div class="subheading grey--text mb-9">Event List</div>

        <v-row>
            <v-col cols="6" offset="6" class="pt-0">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Filter" single-line hide-details></v-text-field>
            </v-col>
        </v-row>
        
        <v-data-table :headers="headers" :items="items" :footer-props="paginationOptions" :search="search"
            item-key="id" :options="pagination" :loading="loading" @click:row="toOrderDetails" @pagination="onPagination">
            <template v-slot:item.eventDate="{ item }">
                {{ formatDate( item.eventDate ) }}
            </template>
            <template v-slot:item.coordinator1.phone="{ item }">
                {{ formatPhoneNum(item.coordinator1.phone) }}
            </template>
        </v-data-table>

    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

export default {
    data: () => ({
        headers     : [
            { text: 'ID', value: 'foreignKey', sortable: true },
            { text: 'Type', value: 'eventType', sortable: true },
            { text: 'Organizer Name', value: 'coordinator1.fullName', sortable: true },
            { text: 'Organizer Phone', value: 'coordinator1.phone', sortable: true },
            { text: 'Date', value: 'eventDate', sortable: true },
            { text: 'Location', value: 'location', sortable: true },
            { text: 'Status', value: 'status', sortable: true },
            { text: 'Origin Store', value: 'originStore.name', sortable: true },
        ],
        pagination: {
            sortBy: ['date']
        },
        paginationOptions: { itemsPerPageOptions: [ 25, 50, -1 ] },
        loading: false,
        search: '',
    }),
    computed: {
        ...mapGetters({
            items       : 'events/events',
            hasMore     : 'events/hasMore',
            needRefresh : 'events/needRefresh'
        }),
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            loadEvents: 'events/loadEvents'
        }),
        async init() {
            try {
                if( !this.needRefresh )
                    return; 

                this.loading = true;
                await this.loadEvents();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
        formatDate(d) {
            return moment(d).format('YYYY-MM-DD')
        },
        toOrderDetails(item) {
            this.$router.push(`/events/${item.id}`);
        },
        onPagination(v) {
            if( v.pageCount != v.page || this.search != '' ) {
                return;
            }

            this.loadMore();
        },
        async loadMore() {
            try {
                if( !this.hasMore )
                    return;
                
                this.loading = true;
                await this.loadEvents();
                this.loading = false;
            }
            catch(error) {
                this.loading = false;
            }
        },
    }
}
</script>

<style lang="scss" scoped>

</style>